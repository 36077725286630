// UserTypeToggle.js
import React from 'react';
import { useTranslation } from 'react-i18next';


function UserTypeToggle({ userType, setUserType }) {
  const { t } = useTranslation();
  const userTypes = [
    'particulier_sociaal',
    'particulier_vrij',
    'kantoorruimte',
    'winkelruimte'
  ];

  return (
    <div className='max-w-1/6 min-w-1/6 mx-auto border border-border rounded-lg p-3 bg-white shadow-md'>
      <form>
        <fieldset>
          <legend className="text-sm font-medium text-copy-900 flex justify-between items-center w-full">
            {t('userType.selection')}
          </legend>
          <div className="mt-2 divide-y divide-gray-200">
            {userTypes.map((type) => (
              <div key={type} className="relative flex items-center py-2">
                <div className="flex-1 min-w-0 text-xs leading-5 pr-2">
                  <label htmlFor={`user-type-toggle-${type}`} className="select-none font-small break-words text-gray-900">
                    {t(`userType.${type}`)}
                  </label>
                </div>
                <div className="flex-shrink-0">
                  <input
                    id={`user-type-toggle-${type}`}
                    type="radio"
                    name="userType"
                    value={type}
                    checked={userType === type}
                    onChange={() => setUserType(type)}
                    className="cursor-pointer rounded-full border-gray-300 text-primary-600 focus:ring-primary-500"
                  />
                </div>
              </div>
            ))}
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default UserTypeToggle;
